import React from 'react';
import ReactRotatingText from 'react-rotating-text';
import IconButtonBar from '../icon-button-bar';
import Image from '../image';
import './style.scss';

function Bio({ author, language = 'ko' }) {
  if (!author) return null;
  const { bio, social, name } = author;
  return (
    <div className="bio">
      <div className="thumbnail-wrapper">
        <Image src={bio.thumbnail} alt="thumbnail" />
        <span className="comment">와플메이커의 기술 이야기</span>
      </div>
    </div>
  );
}

export default Bio;
